import '@/component/immigration/immigration.scss';
import '@/component/main/main.js'; 
// pdf 点击小图看大图
$('.grid').find('.imgwrap').each(function(){
    var _this = $(this);  
    _this.find('.m-pdfbox').click(function(){
        _this.find('.m-popMask').addClass('active'); 
    });
    _this.find('.m-btnClosepop').click(function(){
        _this.find('.m-popMask').removeClass('active'); 
    });
});